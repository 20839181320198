import request from '../utils/service';
import {financeBaseUrl, CHANNEL} from '../unified';
// 获取openId
export function getCode(authCode) {
  return request({
    url: `/dtb/user/getOpenId`,
    method: 'post',
    data: JSON.stringify({
      code: authCode,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
//获取签约状态
export function getContractStatus(openId) {
  return request({
    url: `/dtb/unionPayMiniApp/contractStatus`,
    method: 'post',
    data: JSON.stringify({
      openId: openId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
//  无感支付解约
export function contractRelieve(openId) {
  return request({
    url: `/dtb/unionPayMiniApp/contractRelieve`,
    method: 'post',
    data: JSON.stringify({
      openId: openId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
//  无感支付取消解约
export function contractRelieveCancel(openId) {
  return request({
    url: `/dtb/unionPayMiniApp/contractRelieveCancel`,
    method: 'post',
    data: JSON.stringify({
      openId: openId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
//  获取用户信息
export function getUserDetail(code, openId) {
  return request({
    url: `/dtb/user/getUserDetail`,
    method: 'post',
    data: JSON.stringify({
      code: code,
      openId: openId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

//  获取二维码
export function getRefresh(parmas) {
  return request({
    url: `/dtb/unionPayMiniApp/refresh`,
    method: 'post',
    data: JSON.stringify({
      openId: parmas.openId,
      curQrCode: parmas.curQrCode,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

//  获取我的乘车记录
export function getQueryRecordLog(parmas) {
  return request({
    url: `/dtb/app/queryRecordLog`,
    method: 'post',
    data: JSON.stringify(parmas),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

//  获取我的账单
export function getQueryBill(parmas) {
  return request({
    url: `/dtb/app/queryBill`,
    method: 'post',
    data: JSON.stringify(parmas),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

//  获取进出站,上报
export function saveRecordLog(parmas) {
  return request({
    url: `/dtb/app/saveRecordLog`,
    method: 'post',
    data: JSON.stringify(parmas),
    timeout: 1000,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

//  获取地铁线路和站点
export function getLineStation(parmas) {
  return request({
    url: `/dtb/app/travel/lineStation`,
    method: 'get',
    data: JSON.stringify(parmas),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

//  提交补录站
export function getSupplement(parmas) {
  return request({
    url: `/dtb/app/travel/supplement`,
    method: 'post',
    data: JSON.stringify(parmas),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

//  获取客服页面信息接口
export function getServiceQA(parmas) {
  return request({
    url: `/dtb/service/getServiceQA?openId=` + parmas.openId,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

//  查询用户可开票行程
export function getUserTicketTrans(parmas) {
  console.log(parmas.openId);
  return request({
    url:
      `/dtb/invoice/getUserTicketTrans?openId=` +
      parmas.openId +
      `&assetsFlowId=` +
      parmas.assetsFlowId,
    method: 'post',
    // data: JSON.stringify(parmas),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

// 通过企业名称模糊查询企业
export function findRegisterName(parmas) {
  return request({
    url:
      `/dtb/invoice/findRegisterName?registerName=` +
      parmas.registerName +
      `&openId=` +
      parmas.openId,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
// 通过企业名称查询企业详细信息
export function findRegister(parmas) {
  return request({
    url:
      `/dtb/invoice/findRegister?registerName=` + parmas.registerName + `&openId=` + parmas.openId,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

// 开票申请
export function invoiceApply(parmas) {
  return request({
    url: `/dtb/invoice/invoiceApply`,
    method: 'post',
    data: JSON.stringify(parmas),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

// 开票历史
export function getUserApplyHis(parmas) {
  return request({
    url: `/dtb/invoice/getUserApplyHis?openId=` + parmas.openId,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

// 开票历史详情
export function getInvoiceDetail(parmas) {
  console.log(parmas.openId);
  return request({
    url: `/dtb/invoice/getInvoiceDetail?openId=` + parmas.openId + `&applyId=` + parmas.applyId,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

// 重发邮件
export function reSendMail(parmas) {
  return request({
    url:
      `/dtb//invoice/reSendMail?openId=` +
      parmas.openId +
      `&applyId=` +
      parmas.applyId +
      `&mail=` +
      parmas.mail,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
// 已关注通知接口
export function alreadyFollow(openId) {
  return request({
    url: `/dtb/unionPayMiniApp/followNotifyFromApp`,
    method: 'post',
    data: {
      openId: openId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

//获取最近地铁站信息
export function getStation(parmas) {
  return request({
    url: `/dtb/map/station`,
    method: 'post',
    data: JSON.stringify(parmas),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

//获取地铁站详细信息
export function getStationDetail(parmas) {
  return request({
    url: `/dtb/map/station/detail`,
    method: 'post',
    data: JSON.stringify(parmas),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
//
export function QueryUserManualRepay(openId) {
  return request({
    url: `/dtb/dtbonlineAction/QueryUserManualRepay`,
    method: 'post',
    data: JSON.stringify({
      openId: openId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
//
export function QueryUserManualRepayDetail(params) {
  return request({
    url: `/dtb/dtbonlineAction/QueryUserManualRepayDetail`,
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

//支付
export function QueryQuickpassTN(params) {
  return request({
    url: `/dtb/dtbonlineAction/QueryQuickpassTn`,
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
//支付结果
export function ManualRepayYSFQueryController(params) {
  return request({
    url: `/dtb/dtbonlineAction/ManualRepayYSFQueryController`,
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

//支付取消
export function ManualRepayYSFCancelController(params) {
  return request({
    url: `/dtb/dtbonlineAction/ManualRepayYSFCancelController`,
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

//查询用户抽签结果
export function getDrawIngResult(params) {
  return request({
    url: `${financeBaseUrl}/finance-area/report/get-drawing-result`,
    // url: `https://metroticket.tongyindigitalqpay.com/finance-area/report/get-drawing-result`,
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

// 查询所有可售卡券
export function getAllAvailableCardTypes(params) {
  return request({
    url: `/dtb/cards/allAvailableCardTypes?channel=`+CHANNEL+'&cardCouponsTps='+params.cardCouponsTps,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

// 查询指定可售卡券
export function getCardType(params) {
  return request({
    url: `/dtb/cards/allAvailableCardTypes?channel=`+CHANNEL+'&cardCouponsTps='+params.cardCouponsTps+'&cardCouponsIdx='+params.cardCouponsIdx,
    method: 'post',
    data: JSON.stringify({openId: localStorage.getItem('openId')}),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

// 查询用户可用卡券
export function getUserCards(params) {
  return request({
    url: `/dtb/cards/getUserCards?channel=`+CHANNEL+'&cardCouponsTps='+params.cardCouponsTps+'&cardStatus='+params.cardStatus,
    method: 'post',
    data: JSON.stringify({openId: localStorage.getItem('openId')}),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

// 查询用户可用卡券
export function getBuyToken(params) {
  return request({
    url: `/dtb/cards/getBuyToken?channel=`+CHANNEL+'&cardCouponsIdx='+params.cardCouponsIdx,
    method: 'post',
    data: JSON.stringify({openId: localStorage.getItem('openId')}),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

// 取消卡券购买
export function cancelBuyToken(params) {
  var openId = localStorage.getItem('openId');
  if(!params.openId && openId){
	  params.openId = openId;
  }
	
  return request({
    url: `/dtb/dtbonlineAction/QuickpassCancelPurchaseCardCoupon`,
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

// 查询卡券支付状态
export function queryCardPayResult(params) {
  var openId = localStorage.getItem('openId');
  if(!params.openId && openId){
	  params.openId = openId;
  }
	
  return request({
    url: `/dtb/dtbonlineAction/QueryPurchaseCardCouponResult`,
    method: 'post',
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

/**
 * 
 * @param {
 *  openId
 *  pubmsCode
 * } params 
 * @returns 
 */
export function ctHasActived(params) {
  // return request({
  //   url: `/dtb/ct/has-actived-ticket`,
  //   method: 'POST',
  //   data: JSON.stringify(params),
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  // });
  return Promise.resolve({
    success: true,
    data: {
      hasActived: true,
    },
    msg: 'success'
  })
}

